.post-modal-body {
  max-height: 60vh;
  overflow-y: scroll;
}

.post-modal-body::-webkit-scrollbar {
  width: 20px;
}

.post-modal-body::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

.post-image {
  objectfit: fill;
  width: 55vh;
  height: 50vh;
}

.vote1 {
  position: absolute;
  bottom: -7%;
  margin: 0 auto !important;
  width: 100%;
  display: flex;
  justify-content: center;
}

.vote2 {
  position: absolute;
  bottom: -7%;
  margin: 0 auto !important;
  width: 100%;
  display: flex;
  justify-content: center;
}
p {
  margin: 2px 0 !important;
}
.posts-banner-image {
  display: flex;
}
@media (max-width: 1200px) {
  .posts-banner-image {
    display: block;
  }
  .post-image {
    objectfit: fill;
    width: 100%;
    height: 40vh;
  }
  .media-body{
    margin-bottom: 4rem;
  }
}

@media (max-width: 800px) {
  .posts-banner-image {
    display: block;
  }
  .post-image {
    objectfit: fill;
    width: 100%;
    height: 50vh;
  }
  .media-body{
    margin-bottom: 4rem;
  }
}



.modal-body-container {
  margin-top: 0px !important;
  padding-top: 0px !important;
}

hr {
  margin-top: 10px !important;
  margin-bottom: 0px !important;
}