
.carousel {
	max-width: 1000px;
	margin: auto !important;
	position: relative;
}

.card-image-container {
	position: relative;
	overflow: hidden;
	margin: 26px auto 0 auto;
	width: 100%;
	color: #000;
	font-size: 16px;
	text-align: left;
	box-shadow: 0px 1px 1px 0px #ccc;
	max-width: 796px !important;
	max-height: 448px !important;
}
.card-image-container * {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-transition: all 0.25s ease;
	transition: all 0.25s ease;
}
.card-image-container:before {
	position: absolute;
	top: 10px;
	bottom: 10px;
	left: 10px;
	right: 10px;
	content: "";
	-webkit-transition: all 0.25s ease;
	transition: all 0.25s ease;
	-webkit-transition-delay: 0.25s;
	transition-delay: 0.25s;
}

.card-image {
	padding: 0;
	box-shadow: 0px 1px 1px 0px #ccc;
	object-fit: fill !important;
}
.card-image-hero-img {
	width: 100%;
}
.card-image-heading-container {
	padding: 19px 19px 0;
	display: flex;
	align-items: center;
}
.card-image-social-icon {
	padding: 0;
	border-radius: 50px;
	max-width: 45px;
}
.card-image-title-container {
	padding-left: 10px;
	text-align: left;
}
h3.card-image-title {
	font-size: 23px;
	color: #3a3a3a;
	font-family: arial;
	font-weight: bold;
	margin: 0;
}
.card-image-sub-title {
	font-size: 12px;
	color: #adadad;
	font-family: arial;
	text-transform: uppercase;
	font-weight: bold;
	margin: 0;
}
.icon-container {
	position: absolute;
	bottom: 0;
	right: 10px;
	display: flex;
}
.card-image-text-container {
	position: relative;
}
.card-image-text {
	font-size: 12px;
	line-height: 1.584;
	color: #3a3a3a;
	font-family: arial;
	padding: 19px;
	margin: 0;
	text-align: left;
}
/* image card css */

.card-link {
	padding: 0;
	box-shadow: 0px 1px 1px 0px #ccc;
}

.card-link-hero-img {
	width: 100%;
	height: 170px;
	object-fit: cover;
}

.card-container.video-container{
	border-radius: 15px;
	box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
	height: 323px;
}

.card-container.video-container:hover{
	transform: scale(1.03);
}
/* link card css */

.card-container {
	position: relative;
	overflow: hidden;
	margin: 26px auto 0 auto;
	min-width: 200px;
	max-width: 308px;
	width: 100%;
	color: #000;
	font-size: 16px;
	text-align: left;
	box-shadow: 0px 1px 1px 0px #ccc;
}
.card-container * {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-transition: all 0.25s ease;
	transition: all 0.25s ease;
}
.card-container:before {
	position: absolute;
	top: 10px;
	bottom: 10px;
	left: 10px;
	right: 10px;
	content: "";
	-webkit-transition: all 0.25s ease;
	transition: all 0.25s ease;
	-webkit-transition-delay: 0.25s;
	transition-delay: 0.25s;
}

.card-link-announcement {
	padding: 0;
	box-shadow: 0px 1px 1px 0px #ccc;
	height: 323px;
	position: relative;
}
.card-hero-img {
	width: 100%;
	height: 133px;
}
.card-heading-container {
	padding: 19px 19px 0;
	display: flex;
	align-items: center;
}
.card-social-icon {
	padding: 0;
	border-radius: 50px;
	max-width: 45px;
}
.card-title-container {
	padding-left: 10px;
	text-align: left;
}
h3.card-title {
	font-size: 23px;
	color: #3a3a3a;
	font-family: arial;
	font-weight: bold;
	margin: 0;
}
.card-sub-title {
	font-size: 12px;
	color: #adadad;
	font-family: arial;
	text-transform: uppercase;
	font-weight: bold;
	margin: 0;
}
.icon-container {
	position: absolute;
	bottom: 0;
	right: 10px;
	display: flex;
}
.card-text-container {
	position: relative;
}

.card-text-heading-container {
	padding: 0 19px 0;
	display: flex;
	align-items: center;
}

.card-text {
	font-size: 12px;
	line-height: 1.584;
	color: #3a3a3a;
	font-family: arial;
	padding: 19px;
	padding-top: 0px;
	margin: 0;
	text-align: left;
}
/* HEART ICON - NOT LIKED*/
.fa-trash-alt,
.fa-trash-alt {
	padding: 0.5rem;
	margin-bottom: 10px;
	color: #2a8631;
	z-index: 9999999;
	display: flex;
}

.fa-trash-alt {
	border-radius: 50%;
	cursor: pointer;
}


.fa-trash-alt:hover {
	background-color: #cce9dd;
	border-radius: 50%;
	cursor: pointer;
}

.image-icon{
	background-color: #cce9dd !important;
}

.heart-liked-text {
	margin: 0;
	display: inline-block;
	padding-left: 10px;
	text-transform: uppercase;
	font-family: arial;
	font-weight: 700;
	font-size: 12px;
	margin-top: 1px;
	color: #f6f6f6;
	-webkit-transition: ease-in-out all 0.3s;
	transition: ease-in-out all 0.3s;
}
.liked {
	color: #ed1965;
}
@media (max-width: 800px) {
	h3.card-title {
		font-size: 18px;
	}
	.card-sub-title {
		font-size: 12px;
	}
	.card-text {
		font-size: 12px;
	}
}
/* -------------------------------------------------------------- */
/* SLICK REACT CAROUSEL */
/* -------------------------------------------------------------- */
.slick-next,
.slick-prev {
	top: 117.5%;
}
.slick-next {
	right: 1.5rem;
	text-align: left;
}
.slick-prev {
	text-align: right;
	margin-right: 1.4rem;
	width: 95.3%;
}
.slick-prev:before,
.slick-next:before {
	font-family: "Font Awesome\ 5 Free";
	font-size: 19px;
	color: #fff;
	background-color: #3a3a3a;
	background: linear-gradient(87deg, #2dce89 0, #2dcecc 100%) !important;
	padding: 10px 14px;
	border-radius: 50%;
	line-height: 1;
	opacity: 0.75;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-weight: 900;
}
.slick-prev:before {
	content: "\f053";
}
[dir="rtl"] .slick-prev:before {
	content: "\f054";
}
[dir="rtl"] .slick-next {
	left: -10px;
	top: 70px;
	right: auto;
}
.slick-next:before {
	content: "\f054";
}
[dir="rtl"] .slick-next:before {
	content: "\f053";
}

@media (max-width: 800px) {
	.slick-prev {
		text-align: right;
		margin-right: 1.4rem;
		width: 90.3%;
	}

	.slick-next,
.slick-prev {
	top: 115.5%;
}

}

.uppy-StatusBar.is-waiting .uppy-StatusBar-actionBtn--upload{
	background: linear-gradient(87deg, #2dce89 0, #2dcecc 100%) !important;
}

.no-data {
	text-align: center;
}