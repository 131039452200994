@media (max-width: 1200px) {

  .media-body {
    margin-bottom: 4rem;
  }
}

@media (max-width: 800px) {
  .media-body {
    margin-bottom: 4rem;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}